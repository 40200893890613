<template>
  <section
    class="main-img-container relative overflow-hidden md:h-screen pt-36 md:pb-48 pb-64 dark:bg-green-600/20"
  >
    <transition name="fade" mode="in-out">
      <div
        v-if="currentImage"
        :key="currentImage.src"
        class="bg-no-repeat bg-middle bg-cover image absolute inset-0"
        :style="{ backgroundImage: `url(${currentImage.src})` }"
      >
        <div class="bg-container">
          <div class="header container">
            <h1 class="text-9xl font-bold">놀러오세요</h1>
            <h3 class="text-6xl">{{ placeholderText }}</h3>
            <!-- <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
              nesciunt obcaecati suscipit! At delectus provident est, doloribus
              quas possimus laborum optio a debitis. Tempore inventore nulla
              laborum id minus tenetur, reprehenderit quod voluptatum
              perspiciatis repudiandae dolores facilis dolorem odio suscipit.
            </p> -->
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

// 이미지 배열
const images = [
  {
    src: require("../../../assets/images/gong.jpg"),
    title: "공산성",
  },
  { src: require("../../../assets/images/naejang.jpg"), title: "내장사" },
  { src: require("../../../assets/images/sulak.jpg"), title: "설악산국립공원" },
  { src: require("../../../assets/images/hong.jpg"), title: "홍천 은행나무숲" },
];

const currentIndex = ref(0);
const currentImage = ref(images[0]);

const placeholderText = ref("");
const searchText = ref("");
let index = ref(0);
let rotationInterval = null;

const onSubmit = () => {
  alert(searchText.value);
};

// 타이핑 애니메이션
const typeWriter = () => {
  if (index.value < currentImage.value.title.length) {
    placeholderText.value += currentImage.value.title.charAt(index.value);
    index.value++;
    setTimeout(typeWriter, 100); // 100ms 간격으로 타이핑
  }
};

const initializeTypewriter = () => {
  placeholderText.value = "";
  index.value = 0;
  typeWriter();
};

// 이미지 변경 로직
const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % images.length;
  currentImage.value = images[currentIndex.value];
  initializeTypewriter();
};

// 이미지 순환 시작
const startImageRotation = () => {
  if (!rotationInterval) {
    rotationInterval = setInterval(nextImage, 6000); // 6초 간격으로 이미지 교체
  }
};

// 컴포넌트가 마운트되었을 때 이미지 순환 시작
onMounted(() => {
  startImageRotation();
  initializeTypewriter();
});

// 컴포넌트가 언마운트될 때 이미지 순환 멈춤
onBeforeUnmount(() => {
  if (rotationInterval) {
    clearInterval(rotationInterval);
  }
});
</script>

<style lang="scss" scoped>
.main-img-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  width: 100%;
}
.image {
  width: 100%;
  height: 100%;
  transition: opacity 80s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in, opacity 0.5s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.content {
  position: relative;
  z-index: 1;
}

.test {
  padding: 10px 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
}
.glass-style {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 1);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.search_icon {
  color: white;
  font-size: 30px !important;
  font-weight: 700;
}

.search_input {
  width: 90%;
  height: 100%;
  background: none;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.search_input::placeholder {
  color: white;
  font-size: 30px;
  font-weight: 700;
  opacity: 0.7;
}

.search_input:focus {
  outline: none;
}
.bg-middle {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-container {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.35);
}
.header {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  z-index: 99999;
}
.header > p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}
</style>
