import { Accessibility } from "accessibility";

export default function setAccessibilityTool() {
  const options = {
    labels: {
      resetTitle: "reset (in my language)",
      closeTitle: "close (in my language)",
      menuTitle: "타이틀",
      increaseText: "텍스트 크기 증가",
      decreaseText: "텍스트 크기 감소",
      increaseTextSpacing: "increase text spacing (in my language)",
      decreaseTextSpacing: "decrease text spacing (in my language)",
      increaseLineHeight: "increase line height (in my language)",
      decreaseLineHeight: "decrease line height (in my language)",
      invertColors: "invert colors (in my language)",
      grayHues: "gray hues (in my language)",
      underlineLinks: "underline links (in my language)",
      bigCursor: "big cursor (in my language)",
      readingGuide: "reading guide (in my language)",
      textToSpeech: "텍스트를 음성으로",
      speechToText: "음성을 텍스트로",
      disableAnimations: "disable animations (in my language)",
      hotkeyPrefix: "Hotkey: (in my language)",
    },
    hotkeys: {
      enabled: true,
    },
    icon: {
      circular: true,
    },
    language: {
      textToSpeechLang: "en-US",
      speechToTextLang: "en-US",
    },
  };
  return new Accessibility(options);
}
