import {
  createRouter,
  // createWebHashHistory,
  createWebHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/pages/main/index.vue"),
    meta: {
      title: "No Barrier Travel",
    },
  },
  {
    path: "/tip",
    name: "tip",
    component: () => import("@/pages/tip/index.vue"),
    meta: {
      title: "No Barrier Travel - 여행팁",
    },
  },
  {
    path: "/roomList",
    name: "roomList",
    component: () => import("@/pages/list/index.vue"),
    meta: {
      title: "No Barrier Travel - 숙소",
    },
  },
  {
    path: "/list/:type",
    name: "list",
    component: () => import("@/pages/list/index.vue"),
    meta: {
      title: "No Barrier Travel",
    },
  },
  {
    path: "/list/:type",
    name: "list",
    component: () => import("@/pages/list/index.vue"),
    meta: {
      title: "No Barrier Travel - 리스트",
    },
  },

  {
    path: "/list/:type/:id",
    name: "listDetail",
    component: () => import("@/pages/list/[id]/index.vue"),
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("@/pages/introduce/index.vue"),
    meta: {
      title: "No Barrier Travel - 소개",
    },
  },
  {
    path: "/:404(.*)",
    name: "404",
    component: () => import("@/pages/main/index.vue"),
  },
  {
    path: "/:catchAll(.*)", // 모든 잘못된 경로를 잡아냄
    redirect: "/", // 메인 페이지로 리디렉션
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
