import { createI18n } from "vue-i18n";
import en from "./en/en";
import ko from "./ko/ko";
import ja from "./ja/ja";

export const i18n = createI18n({
  locale: "ko",
  fallbackLocale: "en",
  messages: {
    en: en,
    ja: ja,
    ko: ko,
  },
});
