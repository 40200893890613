import { createStore } from "vuex";
import filterList from "./modules/filterList";

const store = createStore({
  modules: {
    filterList,
  },
});

export default store;
