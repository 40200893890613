<template>
  <div
    class="container-custom relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover"
    :style="{ backgroundImage: `url(${routeInfo.headerImg})` }"
  >
    <div class="absolute inset-0 bg-black opacity-80"></div>
    <div class="container relative">
      <div class="grid grid-cols-1 text-center mt-10">
        <h3
          class="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white"
        >
          {{ routeInfo.headerText }}
        </h3>
      </div>
      <!--end grid-->
    </div>
  </div>
</template>

<script setup>
import { useRouteStore } from "@/util/store/useRouteStore";

const { routeInfo } = useRouteStore();

// 데이터를 정의

// route 변경 감지 및 데이터 업데이트
</script>

<style lang="scss" scoped></style>
