<template>
  <nav id="topnav" class="defaultscroll is-sticky a nav-sticky">
    <div class="relative" :class="container">
      <!-- Logo container-->
      <router-link class="logo" to="/">
        <img
          src="../../assets/images/Logo.png"
          class="inline-block dark:hidden test"
          alt=""
        />
      </router-link>
      <!-- End Logo container-->

      <!-- Start Mobile Toggle -->
      <div class="menu-extras" @click="handler">
        <div class="menu-item">
          <a
            class="navbar-toggle"
            id="isToggle"
            :class="toggle === false ? '' : 'open'"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>
      <!-- End Mobile Toggle -->

      <!--Login button Start-->

      <!--Login button End-->

      <div
        id="navigation"
        :class="toggle === false ? 'none' : 'block'"
        @click="onClickNav"
      >
        <!-- Navigation Menu-->
        <ul class="navigation-menu bold" :class="navLight">
          <li :class="curUrlState === '/list/travel' ? 'active' : ''">
            <router-link to="/list/travel" class="bold">
              {{ $t("nav.travleList") }}
            </router-link>
          </li>

          <li :class="curUrlState === '/list/room' ? 'active' : ''">
            <router-link to="/list/room" class="sub-menu-item bold">{{
              $t("nav.roomList")
            }}</router-link>
          </li>
          <li :class="curUrlState === '/tip' ? 'active' : ''">
            <router-link to="/tip" class="sub-menu-item bold">{{
              $t("nav.tip")
            }}</router-link>
          </li>
          <li :class="curUrlState === '/introduce' ? 'active' : ''">
            <router-link to="/introduce" class="sub-menu-item bold">{{
              $t("nav.introduce")
            }}</router-link>
          </li>

          <li class="has-submenu parent-parent-menu-item">
            <router-link
              to=""
              class="sub-menu-item bold"
              @click="submenu(openMenu === '/languages' ? '' : '/languages')"
              ><i class="fa-solid fa-globe"></i>
            </router-link>
            <ul
              class="submenu px-2"
              :class="['/languages'].includes(openMenu) ? 'open' : ''"
            >
              <li
                @click="onClickChangeLocale('ko')"
                :class="activeIndex === '/aboutus' ? 'active' : ''"
              >
                <div class="sub-menu-item">한국어</div>
              </li>
              <li
                @click="onClickChangeLocale('en')"
                :class="activeIndex === '/features' ? 'active' : ''"
              >
                <div class="sub-menu-item">English</div>
              </li>
            </ul>
          </li>
        </ul>
        <!--end navigation menu-->
      </div>
      <!--end navigation-->
    </div>
    <!--end container-->
  </nav>
  <!--end header-->
  <!-- End Navbar -->
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, computed, watch } from "vue";
import feather from "feather-icons";
import { useRoute } from "vue-router";
import { scrollToPosition } from "@/util/pure/scrollToPosition";
import { useI18n } from "vue-i18n";
import { i18n } from "@/util/i18n/i18n";

const props = defineProps({
  logoLight: {
    type: Boolean,
    required: true,
  },
  navLight: {
    type: String,
    required: true,
  },
  container: {
    type: String,
    required: true,
  },
});

const current = ref("");
const toggle = ref(false);
const activeIndex = computed(() => window.location.pathname);
const route = useRoute();
const curUrlState = ref(null);

watch(
  route,
  () => {
    // 1. 페이지 이동 시 메뉴 닫고 스크롤 최상단
    // 2. 페이지 이동 시 현재 url 상태값 업데이트
    toggle.value = false;
    scrollToPosition(0);
    curUrlState.value = route.path;
  },
  { immediate: true }
);

const onClickNav = (event) => {
  curUrlState.value = event.target.innerText;
};

onMounted(() => {
  // window.addEventListener("scroll", handleScroll);
  // window.addEventListener("scroll", onscroll);
  scrollToPosition(0);
  feather.replace();
});

onUnmounted(() => {
  // window.removeEventListener("scroll", handleScroll);
  // window.removeEventListener("scroll", onscroll);
});

function handler() {
  toggle.value = !toggle.value;
}

function handleScroll() {
  const navbar = document.getElementById("topnav");
  if (window.scrollY >= 50) {
    navbar.classList.add("nav-sticky");
  } else {
    navbar.classList.remove("nav-sticky");
  }
}

function onscroll() {
  const sections = document.querySelectorAll("section");
  const navItems = document.querySelectorAll("nav.container.collapse ul li");
  let currentSectionId = ""; // Define a variable to store the current section's id
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (window.pageYOffset >= sectionTop - 60) {
      currentSectionId = section.getAttribute("id");
    }
  });
  navItems.forEach((li) => {
    if (li.classList.contains(currentSectionId)) {
      li.classList.add("active");
    } else {
      li.classList.remove("active");
    }
  });
  current.value = currentSectionId; // Update the current ref
}
const menu = ref(true);
const openMenu = ref("");
function submenu(item) {
  menu.value = !menu.value;
  openMenu.value = item;
}
function onClickChangeLocale(lang) {
  i18n.global.locale = lang;
}
</script>

<style lang="scss" scoped>
.test {
  width: 200px;
}

.active {
  color: red !important;
}
.glassy {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.submenu > li > div {
  cursor: pointer;
}
.submenu > li > div:hover {
  background-color: rgb(167, 231, 186);
  transition: 0.5s all;
}
</style>
