// src/store/index.js

export default {
  namespaced: true,
  state: {
    selectedOptionsTempPlace: [],
    selectedOptionsTempState: [],
    selectedOptionsTempGubun: [],
    selectedOptionsAll: [],
  },
  mutations: {
    handleCheckboxChange(state, { option, options, selectedOptionsTemp }) {
      if (option.value === "전체") {
        if (selectedOptionsTemp.includes("전체")) {
          selectedOptionsTemp = options.map((opt) => opt.value);
        } else {
          selectedOptionsTemp = [];
        }
      } else {
        if (selectedOptionsTemp.includes(option.value)) {
          // 다른 체크박스 중 하나라도 체크 해제되면 '전체' 체크 해제
          selectedOptionsTemp = selectedOptionsTemp.filter(
            (opt) => opt !== "전체"
          );
        }
      }
      // Update state directly
      state.selectedOptionsTempPlace = selectedOptionsTemp;
    },
    saveSelectOptions(state) {
      state.selectedOptionsAll = state.selectedOptionsTempPlace.filter(
        (option) => option !== "전체"
      );
    },
    clearSelectOptions(state) {
      state.selectedOptionsAll = [];
    },
    removeSelectOptions(state, { option }) {
      // state.selectedOptionsAll.value = state.selectedOptionsAll.value.filter(
      //   (item) => item !== option
      // );
      // state.selectedOptionsAll.value = state.selectedOptionsAll.value;
    },
  },
  actions: {
    handleCheckboxChange({ commit }, payload) {
      commit("handleCheckboxChange", payload);
    },
    saveSelectOptions({ commit }) {
      commit("saveSelectOptions");
    },
    clearSelectOptions({ commit }) {
      commit("clearSelectOptions");
    },
  },
  getters: {
    selectedOptionsTempPlace(state) {
      return state.selectedOptionsTempPlace;
    },
    selectedOptionsAll(state) {
      return state.selectedOptionsAll;
    },
  },
};
