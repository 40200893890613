<template>
  <Navbar :container="'container'" :navLight="'justify-end'" />
  <div>
    <Welcome v-if="isRootRoute" />
    <PageTitle v-else />
  </div>
  <Layout>
    <router-view></router-view>
  </Layout>
  <Footer />
</template>

<script setup>
import { onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import Footer from "./components/footers/footer.vue";
import Navbar from "./components/navbar/navbar.vue";
import setAccessibilityTool from "./util/pure/accessbilityTool";
import Layout from "./components/layout/Layout.vue";
import Welcome from "./pages/main/components/Welcome.vue";
import PageTitle from "./components/pageTitle/PageTitle.vue";
import axios from "axios";
import { useRouteStore } from "./util/store/useRouteStore";

const route = useRoute(); // 현재 경로 정보를 가져옴
const routeStore = useRouteStore();
// 루트 경로 확인
const isRootRoute = computed(() => route.path === "/");

onMounted(() => {
  setAccessibilityTool(); // 접근성 툴 생성자 메소드
});
watch(
  route,
  (newRoute) => {
    routeStore.setRouteInfo(newRoute.path);
  },
  { immediate: true }
);
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* accessibilityTool 540미만 뷰포트에선 안보이게 */
@media (max-width: 539px) {
  ._access-icon {
    display: none !important;
  }
}
</style>
